<template>
    <div class="office_emp_map_block">
        <ContentHeaderSection :title="'Designation Setup'" :icon="'fa fa-cogs'" />
        <div class="p-5 select_geo_map_block">
            <form @submit.prevent="updateMapping">
                <select-input-function v-model="orgform.office_layer_id" :error="orgform.errors.office_layer_id"
                    class="pb-8 w-full lg:w-1/1" label="Name of Office Layer" :action="loadOffice" id="office_layer_id">
                    <option value=null>Select Office Layer</option>
                    <option v-for="officelayer in officelayers" :key="officelayer.id" :value='officelayer.id'>{{
                        officelayer.office_layer_name }}</option>
                </select-input-function>
                <template v-if="showministry">
                    <select-input-function v-model="orgform.ministry_id" :error="orgform.errors.ministry_id"
                        class="pb-8 w-full lg:w-1/1" label="Ministry" :action="loadOffice" id="ministry_id">
                        <option value=null>Select Ministry</option>
                        <option v-for="ministry in ministries" :key="ministry.id" :value='ministry.id'>{{
                            ministry.ministry_name }}</option>
                    </select-input-function>
                </template>
                <template v-if="showregion == true">
                    <select-input-function v-model="orgform.geo_region_id" :error="orgform.errors.geo_region_id"
                        class="pb-8 w-full lg:w-1/1" label="Name of Region" :action="loadProvinces" id="geo_region_id">
                        <option value=null>Select Region</option>
                        <option v-for="region in regions" :key="region.id" :value='region.id'>{{ region.region_name }}
                        </option>
                    </select-input-function>
                </template>
                <template v-if="showprovience == true">
                    <select-input-function v-model="orgform.geo_provience_id" :error="orgform.errors.geo_provience_id"
                        class="pb-8 w-full lg:w-1/1" label="Name of Province" :action="loadMunicipality"
                        id="geo_provience_id">
                        <option value=null>Select Province</option>
                        <option v-for="province in provinces" :key="province.id" :value='province.id'>{{
                            province.provience_name }}</option>
                    </select-input-function>
                </template>
                <template v-if="showmunicipality == true && municipal == true">
                    <select-input-function v-model="orgform.geo_municipality_id"
                        :error="orgform.errors.geo_municipality_id" class="pb-8 w-full lg:w-1/1"
                        label="Name of Municipality" :action="loadBarangay" id="geo_municipality_id">
                        <option value=null>Select Municipality</option>
                        <option v-for="municipal in municipality" :key="municipal.id" :value='municipal.id'>{{
                            municipal.municipality_name }}</option>
                    </select-input-function>
                </template>
                <template v-if="showbarangay == true && barangay == true">

                    <select-input-function v-model="orgform.barangay_id" :error="orgform.errors.barangay_id"
                        class="pb-8 w-full lg:w-1/1" label="Name of Barangay" id="barangay_id" :action="loadOffice">
                        <option value=null>Select Barangay</option>
                        <option v-for="barangay in barangays" :key="barangay.id" :value='barangay.id'>{{
                            barangay.barangay_name }}</option>
                    </select-input-function>

                </template>
                <select-input-function v-model="orgform.office_id" :error="orgform.errors.office_id"
                    class="pb-8 w-full lg:w-1/1" label="Name of Office" v-if="(office_selected == true)"
                    :action="search_data" id="office_id">
                    <option value=null>Select Office</option>
                    <option v-for="office in offices" :value='office.id'>{{ office.office_name }}</option>
                </select-input-function>

                <select-input v-model="orgform.employee_type_id" :error="orgform.errors.employee_type_id"
                    class="pb-8 w-full lg:w-1/1" label="Employee type" v-if="(office_selected == true)"
                    id="employee_type_id">
                    <option value=null>Select Employee type</option>
                    <option v-for="employeeType in employeeTypes" :value='employeeType.id'>{{
                        employeeType.employee_type_name }}
                    </option>
                </select-input>
            </form>
        </div>
    </div>
</template>
<script>
import axios from "axios"
import { ref, toRefs } from "vue"
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import ContentHeaderSection from "@/Shared/ContentHeaderSection"
import FloatingActionBtn from "@/Shared/Buttons/FloatingActionBtn"
import SelectInputFunction from '@/Shared/SelectInputFunction'
import SelectInput from '@/Shared/SelectInput'
export default {
    name: "OfficeLayerBlock",
    props: {
        employee_geo_map: Array,
        regions: Array,
        ministries: Array,
        officelayers: Array,
        //offices: Array,
        employee_id: Number
    },
    components: {
        ContentHeaderSection,
        FloatingActionBtn,
        Head,
        Link,
        SelectInputFunction,
        SelectInput
    },
    setup(props, { emit }) {
        const backendPrefix = ref(process.env.MIX_ADMIN_PREFIX)
        const { employee_geo_map, regions, ministries, officelayers, employee_id } = toRefs(props)
        const orgform = useForm({
            office_layer_id: null,
            employee_type_id: null,
            ministry_id: null,
            geo_region_id: null,
            geo_provience_id: null,
            geo_municipality_id: null,
            barangay_id: null,
        })
        const preloader = ref(false)
        const office_id = ref(null)
        const municipal = ref(false)
        const barangay = ref(false)
        const searchbutton = ref(false)
        const office_selected = ref(false)
        const municipality = ref([])
        const provinces = ref([])
        const barangays = ref([])
        const offices = ref([])
        const employeeTypes = ref([])
        const showministry = ref(false)
        const showregion = ref(false)
        const showprovience = ref(false)
        const showmunicipality = ref(false)
        const showbarangay = ref(false)

        const search_data = (e) => {
            //console.log(e.target.value);
            if (e.target.value <= 0) return emit('getOrganograms', [])
            let data = {
                office_layer_id: e.target.id == 'office_layer_id' ? e.target.value : orgform.office_layer_id,
                office_id: e.target.id == 'office_id' ? e.target.value : orgform.office_id
            }
            //console.log(data);
            preloader.value = true
            axios.post(`/${backendPrefix.value}/offices/office-organogram`, data)
                .then(function (response) {
                    //console.log(response);
                    emit('getGeoInfos', orgform)
                    emit('getOrganograms', response.data)
                    preloader.value = false
                });
        }

        const searchDataEdit = async () => {
            if (orgform.office_id <= 0) return false
            let data = {
                office_layer_id: orgform.office_layer_id,
                office_id: orgform.office_id
            }
            //console.log(data);
            preloader.value = true
            await axios.post(`/${backendPrefix.value}/offices/office-organogram`, data)
                .then(function (response) {
                    //console.log(response);
                    emit('getGeoInfos', orgform)
                    emit('getOrganograms', response.data)
                    preloader.value = false
                });
        }

        const updateMapping = () => {
            //console.log('Office layer data')
        }

        const loadProvinces = async (e) => {

            if (e.target.value > 0) {
                orgform.geo_provience_id = null;
                orgform.geo_municipality_id = null;
                orgform.barangay_id = null;
                orgform.office_id = null;
                let data = {
                    geo_region_id: e.target.id == 'geo_region_id' ? e.target.value : orgform.geo_region_id
                }
                await axios.post('../../provinces/activeprovinces', data)
                    .then(function (response) {
                        provinces.value = response.data;
                        emit('getOrganograms', [])
                        if (orgform.office_layer_id > 0) {
                            orgform.office_id = null;
                            let data1 = {
                                office_layer_id: orgform.office_layer_id,
                                ministry_id: orgform.ministry_id,
                                geo_region_id: data.geo_region_id,
                                geo_province_id: orgform.geo_provience_id,
                                geo_municipality_id: orgform.geo_municipality_id,
                                geo_barangay_id: orgform.barangay_id,
                            }

                            if (data1.office_layer_id == 1) {
                                showministry.value = true
                                showregion.value = false
                                showprovience.value = false
                                showmunicipality.value = false
                                showbarangay.value = false
                            } else if (data1.office_layer_id == 2) {
                                //console.log(e.target.value);
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = false
                                showmunicipality.value = false
                                showbarangay.value = false
                            } else if (data1.office_layer_id == 3) {
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = true
                                showmunicipality.value = false
                                showbarangay.value = false
                            }
                            else if (data1.office_layer_id == 4) {
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = true
                                showmunicipality.value = true
                                showbarangay.value = false
                            }
                            else if (data1.office_layer_id == 5) {
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = true
                                showmunicipality.value = true
                                showbarangay.value = true
                            }
                            //console.log(data1);
                            axios.post(`/${backendPrefix.value}/geomapping/load-offices`, { data: data1 })
                                .then(function (response) {
                                    //console.log(response);
                                    offices.value = response.data
                                    office_selected.value = true;
                                    orgform.office_id = null;
                                    emit('getOrganograms', []);
                                })
                        } else {
                            orgform.office_id = null;
                            offices.value = []
                            office_selected.value = false
                            preloader.value = false;
                            emit('getOrganograms', []);
                            showministry.value = false
                            showregion.value = false
                            showprovience.value = false
                            showmunicipality.value = false
                            showbarangay.value = false
                        }
                    });
            } else {
                provinces.value = [];
                emit('getOrganograms', [])
            }
        }

        const loadProvincesEdit = async () => {

            if (orgform.geo_provience_id > 0) {

                let data = {
                    geo_region_id: orgform.geo_region_id
                }
                await axios.post('../../provinces/activeprovinces', data)
                    .then(function (response) {
                        provinces.value = response.data;
                        loadOffice();
                    });
            } else {
                provinces.value = [];
            }
        }

        const loadMunicipality = async (e) => {
            if (e.target.value > 0) {
                orgform.geo_municipality_id = null;
                orgform.barangay_id = null;
                orgform.office_id = null;
                let data = {
                    geo_provience_id: e.target.id == 'geo_provience_id' ? e.target.value : orgform.geo_provience_id
                }
                await axios.post('../../municipalities/activemunicipalities', data)
                    .then(function (response) {
                        municipality.value = response.data;
                        municipal.value = true;

                        emit('getOrganograms', []);
                        if (orgform.office_layer_id > 0) {
                            orgform.office_id = null;
                            let data1 = {
                                office_layer_id: orgform.office_layer_id,
                                ministry_id: orgform.ministry_id,
                                geo_region_id: orgform.geo_region_id,
                                geo_province_id: data.geo_provience_id,
                                geo_municipality_id: orgform.geo_municipality_id,
                                geo_barangay_id: orgform.barangay_id,
                            }

                            if (data1.office_layer_id == 1) {
                                showministry.value = true
                                showregion.value = false
                                showprovience.value = false
                                showmunicipality.value = false
                                showbarangay.value = false
                            } else if (data1.office_layer_id == 2) {
                                //console.log(e.target.value);
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = false
                                showmunicipality.value = false
                                showbarangay.value = false
                            } else if (data1.office_layer_id == 3) {
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = true
                                showmunicipality.value = false
                                showbarangay.value = false
                            }
                            else if (data1.office_layer_id == 4) {
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = true
                                showmunicipality.value = true
                                showbarangay.value = false
                            }
                            else if (data1.office_layer_id == 5) {
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = true
                                showmunicipality.value = true
                                showbarangay.value = true
                            }
                            //console.log(data1);
                            axios.post(`/${backendPrefix.value}/geomapping/load-offices`, { data: data1 })
                                .then(function (response) {
                                    //console.log(response);
                                    offices.value = response.data
                                    office_selected.value = true;
                                    orgform.office_id = null;
                                    emit('getOrganograms', []);
                                })
                        } else {
                            orgform.office_id = null;
                            offices.value = []
                            office_selected.value = false
                            preloader.value = false;
                            emit('getOrganograms', []);
                            showministry.value = false
                            showregion.value = false
                            showprovience.value = false
                            showmunicipality.value = false
                            showbarangay.value = false
                        }
                    });
            } else {
                municipality.value = [];
                municipal.value = false;
                barangay.value = false;
                emit('getOrganograms', []);
            }
        }

        const loadMunicipalityEdit = async () => {

            if (orgform.geo_provience_id > 0) {

                let data = {
                    geo_provience_id: orgform.geo_provience_id
                }
                await axios.post('../../municipalities/activemunicipalities', data)
                    .then(function (response) {
                        municipality.value = response.data;
                        municipal.value = true;
                        loadOffice();
                    });
            } else {
                municipality.value = [];
                municipal.value = false;
                barangay.value = false;
            }
        }

        const loadBarangay = async (e) => {
            if (e.target.value > 0) {
                orgform.barangay_id = null;
                orgform.office_id = null;
                let data = {
                    geo_municipality_id: e.target.id == 'geo_municipality_id' ? e.target.value : orgform.geo_municipality_id
                }
                await axios.post('../../barangays/activebarangays', data)
                    .then(function (response) {
                        barangays.value = response.data;
                        barangay.value = true;
                        emit('getOrganograms', []);


                        if (orgform.office_layer_id > 0) {
                            orgform.office_id = null;
                            let data1 = {
                                office_layer_id: orgform.office_layer_id,
                                ministry_id: orgform.ministry_id,
                                geo_region_id: orgform.geo_region_id,
                                geo_province_id: orgform.geo_provience_id,
                                geo_municipality_id: data.geo_municipality_id,
                                geo_barangay_id: orgform.barangay_id,
                            }

                            if (data1.office_layer_id == 1) {
                                showministry.value = true
                                showregion.value = false
                                showprovience.value = false
                                showmunicipality.value = false
                                showbarangay.value = false
                            } else if (data1.office_layer_id == 2) {
                                //console.log(e.target.value);
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = false
                                showmunicipality.value = false
                                showbarangay.value = false
                            } else if (data1.office_layer_id == 3) {
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = true
                                showmunicipality.value = false
                                showbarangay.value = false
                            }
                            else if (data1.office_layer_id == 4) {
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = true
                                showmunicipality.value = true
                                showbarangay.value = false
                            }
                            else if (data1.office_layer_id == 5) {
                                showministry.value = false
                                showregion.value = true
                                showprovience.value = true
                                showmunicipality.value = true
                                showbarangay.value = true
                            }
                            //console.log(data1);
                            axios.post(`/${backendPrefix.value}/geomapping/load-offices`, { data: data1 })
                                .then(function (response) {
                                    //console.log(response);
                                    offices.value = response.data
                                    office_selected.value = true;
                                    orgform.office_id = null;
                                    emit('getOrganograms', []);
                                })
                        } else {
                            orgform.office_id = null;
                            offices.value = []
                            office_selected.value = false
                            preloader.value = false;
                            emit('getOrganograms', []);
                            showministry.value = false
                            showregion.value = false
                            showprovience.value = false
                            showmunicipality.value = false
                            showbarangay.value = false
                        }
                    });
            } else {
                barangays.value = [];
                barangay.value = false;
                emit('getOrganograms', []);
            }
        }

        const loadBarangayEdit = async () => {
            if (orgform.geo_municipality_id > 0) {
                let data = {
                    geo_municipality_id: orgform.geo_municipality_id
                }
                await axios.post('../../barangays/activebarangays', data)
                    .then(function (response) {
                        barangays.value = response.data;
                        barangay.value = true;
                        emit('getOrganograms', []);
                    })
            } else {
                barangays.value = [];
                barangay.value = false;
            }
        }

        const loadOffice = async (e) => {

            if (e.target.value > 0 || orgform.office_layer_id > 0) {

                orgform.office_id = null;
                let data = {
                    office_layer_id: e.target.id == 'office_layer_id' ? e.target.value : orgform.office_layer_id,
                    ministry_id: e.target.id == 'ministry_id' ? e.target.value : orgform.ministry_id,
                    geo_region_id: e.target.id == 'geo_region_id' ? e.target.value : orgform.geo_region_id,
                    geo_province_id: e.target.id == 'geo_provience_id' ? e.target.value : orgform.geo_provience_id,
                    geo_municipality_id: e.target.id == 'geo_municipality_id' ? e.target.value : orgform.geo_municipality_id,
                    geo_barangay_id: e.target.id == 'barangay_id' ? e.target.value : orgform.barangay_id,
                }

                if (data.office_layer_id == 1) {
                    showministry.value = true
                    showregion.value = false
                    showprovience.value = false
                    showmunicipality.value = false
                    showbarangay.value = false
                } else if (data.office_layer_id == 2) {
                    showministry.value = false
                    showregion.value = true
                    showprovience.value = false
                    showmunicipality.value = false
                    showbarangay.value = false
                } else if (data.office_layer_id == 3) {
                    showministry.value = false
                    showregion.value = true
                    showprovience.value = true
                    showmunicipality.value = false
                    showbarangay.value = false
                }
                else if (data.office_layer_id == 4) {
                    showministry.value = false
                    showregion.value = true
                    showprovience.value = true
                    showmunicipality.value = true
                    showbarangay.value = false
                }
                else if (data.office_layer_id == 5) {
                    showministry.value = false
                    showregion.value = true
                    showprovience.value = true
                    showmunicipality.value = true
                    showbarangay.value = true
                }

                await axios.post(`/${backendPrefix.value}/geomapping/load-offices`, { data: data })
                    .then(function (response) {
                        offices.value = response.data
                        office_selected.value = true;
                        orgform.office_id = null;
                        emit('getOrganograms', []);
                    })
            } else {
                orgform.office_id = null;
                offices.value = []
                office_selected.value = false
                preloader.value = false;
                emit('getOrganograms', []);
                showministry.value = false
                showregion.value = false
                showprovience.value = false
                showmunicipality.value = false
                showbarangay.value = false
            }
        }

        const loadOfficeEdit = async () => {

            let data = {
                office_layer_id: orgform.office_layer_id,
                ministry_id: orgform.ministry_id,
                geo_region_id: orgform.geo_region_id,
                geo_province_id: orgform.geo_provience_id,
                geo_municipality_id: orgform.geo_municipality_id,
                geo_barangay_id: orgform.barangay_id,
            }
            if (data.office_layer_id == 1) {
                showministry.value = true
                showregion.value = false
                showprovience.value = false
                showmunicipality.value = false
                showbarangay.value = false
            } else if (data.office_layer_id == 2) {
                //console.log(e.target.value);
                showministry.value = false
                showregion.value = true
                showprovience.value = false
                showmunicipality.value = false
                showbarangay.value = false
            } else if (data.office_layer_id == 3) {
                showministry.value = false
                showregion.value = true
                showprovience.value = true
                showmunicipality.value = false
                showbarangay.value = false
            }
            else if (data.office_layer_id == 4) {
                showministry.value = false
                showregion.value = true
                showprovience.value = true
                showmunicipality.value = true
                showbarangay.value = false
            }
            else if (data.office_layer_id == 5) {
                showministry.value = false
                showregion.value = true
                showprovience.value = true
                showmunicipality.value = true
                showbarangay.value = true
            }
            if (orgform.barangay_id > 0 || orgform.ministry_id > 0 || orgform.geo_provience_id > 0 || orgform.geo_municipality_id > 0 || orgform.office_layer_id == 2) {
                office_selected.value = true;
            }
            await axios.post(`/${backendPrefix.value}/geomapping/load-offices`, { data: data })
                .then(function (response) {
                    //console.log(response);
                    offices.value = response.data

                })

        }

        const searchbuttonstatus = async (e) => {
            if (e.target.value > 0) {
                office_id.value = e.target.value
                searchbutton.value = true
            } else {
                searchbutton.value = false
            }
        }

        const getTypes = async () => {

            let data = {
            }
            await axios.post(`/${backendPrefix.value}/employeetypes/active`, data)
                .then(function (response) {
                    employeeTypes.value = response.data
                });
        }
        return {
            preloader,
            orgform,
            employee_geo_map,
            regions,
            ministries,
            provinces,
            municipality,
            officelayers,
            offices,
            employee_id,
            updateMapping,

            loadProvinces,
            loadMunicipality,
            loadBarangay,
            barangays,
            loadOffice,
            municipal,
            barangay,
            office_selected,
            searchbutton,
            searchbuttonstatus,
            search_data,
            office_id,
            loadMunicipalityEdit,
            loadBarangayEdit,
            loadOfficeEdit,
            searchDataEdit,
            loadProvincesEdit,
            showministry,
            showregion,
            showprovience,
            showmunicipality,
            showbarangay,
            getTypes,
            employeeTypes
        }
    },
    created: function () {
        this.loadProvincesEdit();
        this.loadMunicipalityEdit();
        this.loadBarangayEdit();
        this.loadOfficeEdit();
        this.searchDataEdit();
        this.getTypes();
    }
}
</script>
<style lang="scss" scoped>
$checkbox_size: 20;

.office_emp_map_block {
    display: block;
    position: relative;

    // padding-bottom: 50px;
    .select_geo_map_block {
        display: block;

        :deep(.action_btn) {
            right: 20px;
            bottom: 20px;
        }

        &>div {
            display: flex;
            gap: 10px;
            align-items: center;

            .checkbox_block {
                display: inline-block;
                width: #{$checkbox_size}px;
                height: #{$checkbox_size}px;
                line-height: #{$checkbox_size}px;
                text-align: center;
                font-size: 10px;
                border: 1px solid #ddd;
                border-radius: 5px;
                cursor: pointer;
                transition: all 0.4s;

                &>i {
                    display: none;
                    opacity: 0;
                    line-height: #{$checkbox_size}px;
                    transition: all 0.4s;
                }

                &:hover {
                    color: #ccc;
                    border-color: #ccc;

                    &>i.checked {
                        display: block;
                        opacity: 1;
                    }
                }

                &.active {
                    color: #006699;
                    border-color: #006699;

                    &>i.checked {
                        display: block;
                        opacity: 1;
                    }

                    &:hover {
                        color: #CD0000;
                        border-color: #CD0000;

                        &>i.checked {
                            display: none;
                            opacity: 0
                        }

                        &>i.remove {
                            display: block;
                            opacity: 1;
                        }
                    }
                }
            }

            .info_block {
                &.active {
                    font-weight: 600;
                }
            }
        }
    }
}
</style>
