<template>
    <div>
        <Breadcrumbs :type="type" :header="title" :url="url" :head="head"></Breadcrumbs>
        <trashed-message v-if="employeetype.deleted_at" class="mb-6" @restore="restore"> This Employee Type has been
            deleted. </trashed-message>
        <div class="max-w-lg bg-white rounded-md shadow overflow-hidden">
            <form @submit.prevent="update">
                <div class="flex flex-wrap -mb-8 -mr-6 p-8">
                    <text-input v-model="form.employee_type_name" :error="form.errors.employee_type_name"
                        class="pb-8 pr-6 w-full lg:w-1/1" label="Name Of Type" placeholder="i.e Permanent"
                        @keyup="slugBuild" />
                    <text-input v-model="form.slug" :error="form.errors.slug" class="pb-8 pr-6 w-full lg:w-1/1"
                        label="Name of Slug" required="true" placeholder="i.e permanent" />
                    <status-toogle-button v-model="form.status" :error="form.errors.status" label="Employee type Status"
                        class="pb-8 pr-6 w-full lg:w-1/1" :checked="statusCheck"></status-toogle-button>
                </div>
                <div class="flex items-center px-8 py-4 bg-gray-50 border-t border-gray-100">
                    <button v-if="!employeetype.deleted_at && permission.edit > 0" class="text-red-600 hover:underline"
                        tabindex="-1" type="button" @click="destroy"><delete-icon></delete-icon></button>
                    <loading-button v-if="permission.edit > 0" :loading="form.processing" class="btn-indigo ml-auto"
                        type="submit">Update Employee Type</loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import SelectInput from '@/Shared/SelectInput'
import LoadingButton from '@/Shared/LoadingButton'
import TrashedMessage from '@/Shared/TrashedMessage'
import StatusToogleButton from '@/Shared/StatusToogleButton'
import Breadcrumbs from '@/Shared/Breadcrumbs'
import DeleteIcon from '@/Shared/DeleteIcon'
export default {
    components: {
        Head,
        Icon,
        Link,
        LoadingButton,
        SelectInput,
        TextInput,
        TrashedMessage,
        StatusToogleButton,
        Breadcrumbs,
        DeleteIcon,
    },
    layout: Layout,
    props: {
        employeetype: Object,
        permission: Object,
    },
    remember: 'form',
    data() {
        return {
            form: this.$inertia.form({
                employee_type_name: this.employeetype.employee_type_name,
                slug: this.employeetype.slug,
                status: this.employeetype.status,
            }),
            type: "edit",
            title: this.employeetype.employee_type_name,
            head: 'Employee type',
            url: '/backend/employeetypes',
        }
    },
    methods: {
        update() {
            this.form.put(`/backend/employeetypes/${this.employeetype.id}`)
        },
        destroy() {
            if (confirm('Do you really want to delete this employeetype?')) {
                this.$inertia.form.delete(`/backend/employeetypes/${this.employeetype.id}`)
            }
        },
        restore() {
            if (confirm('Are you sure you want to restore this employeetype?')) {
                this.$inertia.put(`/backend/employeetypes/${this.employeetype.id}/restore`)
            }
        },
        statusCheck() {
            if (this.employeetype.status == 1) {
                this.form.status = true;
                return true;
            } else {
                this.form.status = false;
                return false;
            }
        },
        slugBuild() {
            this.form.slug = this.form.employee_type_name.replace(/\s/g, '-').toLowerCase()
        },
    },
    created: function () {
        this.statusCheck();
    }
}
</script>
